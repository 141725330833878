<template>
    <ErrorPage :error="error"/>
</template>

<script>
import ErrorPage from '@/components/Layouts/ErrorPage';

export default {
    name: "PageNotFound",
    components: { ErrorPage },
    data: () => ({
        error: {
            code: 404,
            message: 'pageNotFound'
        }
    })
};
</script>
